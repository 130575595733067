import {
  withValidation,
  composeSDKFactories,
  createElementPropsSDKFactory,
  changePropsSDKFactory,
  clickPropsSDKFactory,
  assert,
  childrenPropsSDKFactory,
  toJSONBase,
  isValidStateReference,
  backgroundPropsSDKFactory,
} from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { CorvidSDKFactory, SdkInstance } from '@wix/editor-elements-types';
import {
  IMultiStateBoxSDKFactory,
  IMultiStateBoxStateSDK,
  IMultiStateBoxProps,
  IMultiStateBoxSDK,
} from '../MultiStateBox.types';

const multiStateBoxState: CorvidSDKFactory = ({
  getSdkInstance,
  metaData,
}) => ({
  get type() {
    return '$w.State';
  },
  toJSON() {
    const sdkInstance = getSdkInstance();
    return {
      ...toJSONBase(metaData),
      type: sdkInstance.type,
      background: sdkInstance.background.src,
    };
  },
});

export const stateBoxStateSdk = composeSDKFactories(
  backgroundPropsSDKFactory,
  multiStateBoxState,
);

const stateBoxSDKFactory: IMultiStateBoxSDKFactory = api => {
  const { props, getChildren, metaData, setProps } = api;

  const createStateSDKInstance = (sdkInstance: SdkInstance) => ({
    ...sdkInstance,
    ...stateBoxStateSdk(api),
  });

  const sdk = {
    get currentState() {
      const states = getChildren();
      const selectedState = states.find(
        ({ uniqueId }) => uniqueId === props.selectedStateId,
      );
      const instance = selectedState ? selectedState : states[0];
      return createStateSDKInstance(instance);
    },
    get states() {
      return getChildren().map(createStateSDKInstance);
    },
    changeState(stateReference: string | IMultiStateBoxStateSDK) {
      const states = getChildren();
      const currentStateId = props.selectedStateId;
      const nextState = assert.isString(stateReference)
        ? states.find(({ role }) => role === stateReference)
        : stateReference;
      const nextStateId = nextState?.uniqueId;
      if (currentStateId === nextStateId) {
        return Promise.resolve(this.currentState);
      }
      return new Promise<IMultiStateBoxStateSDK>(resolve => {
        setProps({ selectedStateId: nextStateId });
        resolve(nextState);
      });
    },
    get type() {
      return '$w.MultiStateBox';
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type: sdk.type,
        currentStateId: sdk.currentState.id,
      };
    },
  };
  return sdk;
};

const customRules = {
  changeState: [isValidStateReference],
};

const stateBox = withValidation(
  stateBoxSDKFactory,
  {
    type: ['object'],
    properties: {
      changeState: {
        type: ['function'],
        args: [{ type: ['object', 'string'] }],
      },
    },
  },
  customRules,
);

export const sdk = composeSDKFactories<IMultiStateBoxProps, IMultiStateBoxSDK>(
  createElementPropsSDKFactory(),
  changePropsSDKFactory,
  clickPropsSDKFactory,
  childrenPropsSDKFactory,
  stateBox,
);

export default createComponentSDKModel(sdk);
